<template>
  <div id="knowledge">
    <div class="page_title">
      <span>知识库</span>
    </div>
    <ul>
      <li v-for="(item, index) in categories" :key="index" @click="toDetail(item)">
        <img :src="require(`../../assets/images/knowledge/${item.icon}`)" alt="">
        <p>{{ item.name }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Knowledge',
  components: { },
  props: [],
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(['categories'])
  },
  watch: {},
  created() {
    this.getKnowledgeCategoryObj()
  },
  mounted() {},
  methods: {
    ...mapActions({
      'getKnowledgeCategoryObj': 'knowledge/getKnowledgeCategoryObj'
    }),
    toDetail({ list, code, id, name }) {
      this.$router.push({ name: 'knowledgeDetail', params: { code }})
    }
  }
}
</script>

<style lang="less" scoped>
#knowledge{
  padding-top: .75rem;
  ul{
    display: flex;
    flex-wrap: wrap;
    li{
      width: 3rem;
      height: 3rem;
      border-radius: .1rem;
      background: #fff;
      text-align: center;
      margin: .12rem 0 0 .12rem;
      img{
        width: .72rem;
        height: .91rem;
        padding-top: .79rem;
        margin-bottom: .14rem;
      }
      p{
        font-size: .27rem;
        font-weight: 400;
        line-height: .38rem;
      }
    }
  }
}
</style>
